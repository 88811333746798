<template>
  <div class="equipment bgFFF" v-loading="loading">
    <div class="e_content">
      <div class="ruleMessage bgFFF">
        <div class="bind">
          <div class="subTitle">绑定详情</div>
          <div class="detail">
            <div class="item">
              <div class="label">规则名称：</div>
              <div class="content">{{ title }}</div>
            </div>
            <div class="item" v-if="this.formData.descMsg">
              <div class="label">规则描述：</div>
              <div class="content desc" v-html="descFilter"></div>
            </div>
            <div class="item group" v-if="roadGroup.length">
              <div class="label">绑定车场：</div>
              <div class="content">
                <div class="tabRow" v-for="item in roadGroup" :key="item.chargeTopId">
                  <div class="rowItem">
                    {{ item.parkName }}
                  </div>
                  <div class="rowItem">{{ item.parkCarGroupName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rules">
          <div class="subTitle">规则详情</div>
          <div class="detail">
            <div class="list" v-for="item in tableData" :key="item.id">
              <div class="date">
                <span class="version">V{{ item.version }}</span>
                生效日期
                <span class="time">{{ item.effectiveDate }}</span>
              </div>
              <div class="box" v-html="item.descMsg"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="submit">
        <el-button @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bindRoadGroup",
  components: {},
  computed: {
    descFilter() {
      return this.formData.descMsg.replace(/\n/g, "<br>");
    },
  },
  data() {
    return {
      loading: false,
      roadGroup: [],
      roadGroupId: 0,
      roadGroupIndex: -1,
      formData: {
        descMsg: "",
        roadGroupIdList: "",
        chargeTopId: "",
      },
      tableData: [],
      title: "",
    };
  },
  methods: {
    // 路外计费规则绑定的车场列表
    queryRoadGroup() {
      this.$axios
        .post("/acb/2.0/roadOutEngine/queryChargeListById", {
          data: {
            chargeTopId: this.formData.chargeTopId,
            pageNum: 1,
            pageSize: 1000,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.roadGroup = res.value.list ?? [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 搜索计费规则列表
    searchData() {
      this.loading = true;
      this.$axios
        .post("/acb/2.0/roadInEngine/queryListByTopId", {
          data: {
            topId: this.formData.chargeTopId,
            pageNum: 1,
            pageSize: 1000,
            source: 2
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list ?? [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 搜索计费规则详情
    queryRuleDetail() {
      this.$axios
        .post("/acb/2.0/roadOutEngine/queryFeeList", {
          data: {
            topId: this.formData.chargeTopId,
            pageNum: 1,
            pageSize: 1000,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            const ruleInfo = res.value.list[0] ?? {};
            this.title = ruleInfo.title ?? "";
            this.formData.descMsg = ruleInfo.descMsg ?? "";
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  created() {
    this.formData.chargeTopId = this.$route.query.topId;
    this.queryRuleDetail();
    this.searchData();
    this.queryRoadGroup();
  },
};
</script>
<style scoped lang="stylus">
.equipment {
  height: calc(100vh - 138px);
}
.equipment .e_content {
  box-sizing: border-box;
  color: #48576a;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ruleMessage {
  flex: 1;
  display: flex;
}
.bind,
.rules {
  flex: 1;
  padding: 16px 16px;
}
.subTitle {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #303133;
  margin-bottom: 16px;
  position: relative;
  padding-left: 12px;
}
.subTitle::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 3px;
  height: 15px;
  background: #409eff;
}
.rules {
  position: relative;
}
.item {
  display: flex;
  margin-bottom: 16px;
}
.item:first-child {
  align-items: center;
}
.item:first-child .content {
  height: 31px;
  background: #f2f3f5;
  border-radius: 2px 2px;
  border: 1px solid #e4e7ec;
  box-sizing: border-box;
  line-height: 31px;
  padding-left: 12px;
  color: #86909c;
}
.label {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-size: 14px;
  color: #616266;
  margin-right: 10px;
}
.content {
  flex: 1;
  &.desc {
    line-height: 1.5;
  }
}
.group .label {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}
.tabRow {
  display: flex;
  justify-content: space-between;
}
.rowItem {
  width: calc(100% - 100px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 10px;
  font-family: PingFang SC, PingFang SC;
}
.rowItem:first-child {
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: #4e5969;
}
.rowItem:nth-child(2) {
  font-size: 14px;
  color: #86909c;
}
.rowItem:last-child {
  width: 100px;
  text-align: right;
}
.rowItem:last-child .el-icon-remove-outline {
  float: right;
  cursor: pointer;
}
.el-icon-circle-plus-outline {
  cursor: pointer;
  color: #409eff;
}
.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.date,
.version {
  margin-bottom: 12px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-size: 14px;
  color: #4e5969;
}
.time {
  color: #409eff;
}
.detail {
  padding-left: 12px;
}
.rules .detail {
  max-height: calc(100% - 32px);
  overflow-y: auto;
  min-height: 0;
  width: calc(100% - 32px);
  position: absolute;
  top: 46px;
  bottom: 0;
}
.box {
  background: #f2f3f5;
  border-radius: 2px 2px;
  border: 1px solid #e4e7ec;
  min-height: 50px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #4e5969;
  line-height: 20px;
  padding: 10px 10px;
  margin-right: 28px;
}
>>> .oneDesc {
  font-size: 14px;
  font-weight: 600;
  color: #303133;
  margin-bottom: 6px;
}
>>> .twoDesc {
  color: #4e5969;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-size: 14px;
  margin-bottom: 2px;
}
>>> .threeDesc {
  margin-left: 30px;
  margin-bottom: 2px;
}
>>> .forthDesc {
  margin-left: 60px;
  margin-bottom: 2px;
}
>>> .fiveDesc {
  margin-left: 30px;
  line-height: 26px;
}
.submit {
  margin: 0 auto;
  margin: 20px 20px;
  display: flex;
  justify-content: center;
}
>>> .submit .el-button--primary,
.submit .el-button--default {
  width: 96px;
}
>>> .el-textarea__inner {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #4e5969;
  line-height: 20px;
}
</style>
